<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="800px">
      <div class="bg-white poppins">
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">SKU</h4>
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
        </div>
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <form class="pt-4">
            <v-autocomplete
              v-model="formData.skuId"
              label="SKU"
              item-text="systemCode"
              item-value="skuId"
              clearable
              outlined
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"
              hide-no-data
              hide-selected
              @change="updateSystemCode"
              :error-messages="skuIdErrors"
              @input="$v.formData.skuId.$touch()"
              @blur="$v.formData.skuId.$touch()"
              dense
            ></v-autocomplete>
            <v-text-field
              v-model="formData.quantity"
              label="Quantity"
              type="Number"
              clearable
              outlined
              dense
              @change="() => validateMinValue('quantity', 0)"
              :error-messages="quantityErrors"
              @input="$v.formData.quantity.$touch()"
              @blur="$v.formData.quantity.$touch()"
            ></v-text-field>
          </form>
          <!--end::Body-->
          <div class="mb-4 d-flex flex-grow-1 align-end justify-center mt-4">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetFormData"
            >
              Reset
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
// import { getToken } from "@/core/services/jwt.service";
import ApiService from "@/core/services/api.service";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
export default {
  name: "EditSku",
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      skuId: { required },
      code: { required },
      quantity: { required },
      description: { required },
      goods_price: {
        required: requiredIf(function () {
          return this.is_unit_price_required;
        }),
      },
    },
  },
  props: [
    "item",
    "actionFunction",
    "skuItem",
    "oldIndex",
    "clientId",
    "is_unit_price_required",
  ],
  data: () => ({
    dialog: false,
    isLoading: false,
    search: null,
    count: null,
    entries: [],
    formData: {
      skuId: null,
      systemCode: null,
      code: null,
      quantity: null,
      price: null,
      description: null,
      store_code: null,
      goods_price: null,
    },
  }),
  methods: {
    updateSystemCode() {
      let data = false;
      this.items.forEach((item) => {
        if (item.skuId == this.formData.skuId) {
          data = item;
        }
      });
      for (const [key, value] of Object.entries(data)) {
        this.formData[`${key}`] = value;
      }
      this.formData.code = data.systemCode;
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        let copy = { ...this.formData };
        this.actionFunction(copy, this.oldIndex);
        this.toggleModal();
      }
    },
    resetFormData() {
      this.$v.$reset();
      this.entries = this.skuItem;
      if (this.item) {
        this.getData(this.item.systemCode);
        let copy = { ...this.item };
        Object.entries(this.formData).forEach((localData) => {
          Object.entries(this.item).forEach((serverData) => {
            if (localData[0] == serverData[0]) {
              this.formData[localData[0]] = copy[serverData[0]];
            }
          });
        });
      } else {
        this.formData = {
          skuId: null,
          code: null,
          quantity: null,
          price: null,
          description: null,
          store_code: null,
          goods_price: null,
        };
      }
    },
    toggleModal() {
      this.resetFormData();
      this.dialog = !this.dialog;
    },
    async getData(value) {
      try {
        const payload = {
          customer_id: this.selectedCustomer,
          type: "sku",
          query: value,
        };

        const { data } = await ApiService.post(
          `/orderManagement/returns_management/search`,
          payload
        );
        this.entries = data.sku;
      } catch {
        //
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    items: function () {
      return this.entries;
    },
    skuIdErrors: function () {
      return this.handleFormValidation("skuId", this);
    },
    quantityErrors: function () {
      return this.handleFormValidation("quantity", this);
    },
    descriptionErrors: function () {
      return this.handleFormValidation("description", this);
    },
    codeErrors: function () {
      return this.handleFormValidation("code", this);
    },
    goods_priceErrors: function () {
      return this.handleFormValidation("goods_price", this);
    },
    selectedCustomer: function () {
      return this.$store.getters.getPENDINGRETURNSSelectedCustomer;
    },
  },
  watch: {
    search(val) {
      this.isLoading = true;

      this.getData(val);
    },
  },
};
</script>
